export const DEVICE_STATUS = {
  SENT: 0,
  RECEIVED: 1,
  DELIVERED: 2,
  RETURNING: 3,
  INSTOCK: 4,
  INTRANSIT: 5,
  RETURNED: 6
}

export const SIM_STATUS_NAME = ["Active", "Inactive"]

export const sendDeviceStatus = {
  0: { label: "308_32118", color: "" },
  1: { label: "308_32119", color: "orange" },
  2: { label: "308_32120", color: "#108ee9" },
  3: { label: "308_32121", color: "#f50" }
}

export const reportStatus = {
  0: { label: "I1_9595;33_33258;33_33254", color: "" },
  1: { label: "I1_9607;33_33258;33_33254", color: "" },
  2: { label: "I1_9619;33_33258;33_33254", color: "" }
}

export const receiveDeviceStatus = {
  1: { label: "I1_4498;33_33258;33_33254", color: "orange" },
  2: { label: "I1_4511;33_33258;33_33254", color: "#108ee9" },
  3: { label: "I1_4524;33_33258;33_33254", color: "#f50" }
}

export const activityStatus = {
  0: { label: "308_32122", color: "" },
  1: { label: "308_32125", color: "orange" },
  2: { label: "308_32124", color: "#108ee9" },
  3: { label: "308_32126", color: "#f50" },
  4: { label: "Pickingup", color: "#10e975" },
  5: { label: "Standby", color: "#e910ba" },
  6: { label: "Waiting", color: "#e95510" },
  7: { label: "Started", color: "#10b3e9" }
}

export const activityTypeDropdown = {
  0: { label: "308_32122", color: "" },
  1: { label: "308_32125", color: "orange" },
  2: { label: "308_32124", color: "#108ee9" },
  3: { label: "308_32126", color: "#f50" }
}

export const activityType = {
  0: "309_32127",
  1: "309_32128",
  2: "309_32129",
  3: "309_32130",
  4: "309_32131"
}

export const enumActivityType = Object.freeze({
  Ride: 0,
  Meeting: 1,
  Airport: 2,
  Rental: 3,
  Package: 4
})

export const addressType = [
  { label: "Other", value: 0 },
  { label: "Home", value: 1 },
  { label: "Work", value: 2 }
]

export const enumVehicleStatus = Object.freeze({
  InStock: 0,
  Sent: 1,
  Delivered: 2,
  Returning: 3
})

export const enumActivityStatus = Object.freeze({
  Ongoing: 0,
  Upcoming: 1,
  Completed: 2,
  Cancelled: 3,
  PickingUp: 4,
  Standby: 5,
  Waiting: 6,
  Started: 7
})

export const segmentColor = ["#f24822", "#ffcd29", "#0d99ff", "#14ae5c", "#9747ff", "#1e1e1e"]
