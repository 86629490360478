export const RESOURCE_KEY = {
  ROOT_GW_ORGANIZATION: "root_gw_organization",
  ROOT_WRAPPER_MASTER_DATA: "root_wrapper_master_data",
  ROOT_WRAPPER: "root_wrapper",
  ROOT_WRAPPER_GROUP_MASTER_DATA: "root_wrapper_group_master_data",
  ROOT_WRAPPER_GROUP: "root_wrapper_group",
  ROOT_ALERT_RULE_MASTER_DATA: "root_alert_rule_master_data",
  ROOT_ALERT_RULE: "root_alert_rule",
  ROOT_USER: "root_user",
  ROOT_IDM_ORGANIZATION: "root_idm_organization",
  ROOT_POLICY: "root_policy",
  ROOT_ROLE: "root_role",
  ROOT_IDENTITY_PROVIDER: "root_identity_provider",
  ROOT_OAUTH_CLIENT: "root_oauth_client",
  ROOT_APPLICATION: "root_application",
  ROOT_VEHICLE: "root_ac_client_vehicle",
  ROOT_VEHICLE_GROUP: "root_vehicle_group",
  ROOT_VEHICLE_DEPOT: "root_vehicle_depot",
  ROOT_RESOURCE: "root_resource",
  ROOT_DESIGN_FILE: "root_design_file",
  ROOT_TRANSLATION_VERSION: "root_translation_version",
  ROOT_UI_MANAGEMENT: "root_ui_management",
  ROOT_TRANSLATION_TASK: "root_translation_task",
  ROOT_TRANSLATION_ELEMENT: "root_translation_element",
  ROOT_CLIENT_DICTIONARY: "root_client_dictionary",
  ROOT_SYSTEM_ERROR: "root_system_error",
  ROOT_ALL_RESOURCES: "root_all_resources",
  ROOT_PROJECT_RESOURCE: "root_project_resource",
  ROOT_SERVICE: "root_service",
  ROOT_APPROVAL_SETTING: "root_approval_setting",
  ROOT_APPROVAL: "root_approval",
  ROOT_FUNCTION_SETTING: "root_idm_func_setting",
  SEND_DEVICE: "root_send_device",
  ROOT_COMPANY_SETTING: "root_company_setting",
  ROOT_DEPARTMENT: "root_department",
  ROOT_BUSSINESS_HOUR: "root_bussiness_hour",
  ROOT_OVERTIME_RULE: "root_overtime_rule",
  ROOT_HOLIDAY: "root_holiday",
  ROOT_EMERGENCY_CONTACT: "root_emergency_contact",
  ROOT_DRIVER: "root_driver",
  SEND_DRIVER: "root_send_device",
  ROOT_REPORT: "root_ac_report",
  ROOT_EXPENSE_CATEGORY: "root_expense_category",
  ROOT_PASSENGER: "root_passenger",
  ROOT_CHAUFFEUR_ACTIVITY: "root_chauffeur_connect_activity",
  ROOT_CHAUFFEUR_SETTING: "root_chauffeur_connect_setting",
  ROOT_SEND_NOTIFICATION: "root_send_notification",
  ROOT_DASHBOARD: "root_dashboard_idm",
  ROOT_BYPASS: "root_by_pass",
  ROOT_CC_ACTIVITY: "root_cc_activity",
  ROOT_CC_ORGANIZATION_SETTING: "root_cc_organization_setting",
  ROOT_SEGMENT: "root_segment",
  ROOT_CC_ACTIVITY_WITH_EXPENSE: "root_cc_activity_with_expense",
  ROOT_EXPENSE_CATEGORY_TYPE: "root_expense_category_type",
  ROOT_DRIVER_OVERTIME: "root_driver_overtime"
}

export const OBJECT_PAGE = {
  VEHICLE: "client_vehicle",
  VEHICLE_GROUP: "vehicle_group",
  VEHICLE_DEPOT: "vehicle_depot",
  DRIVER: "driver",
  REPORT: "expense_report",
  EXPENSE_CATEGORY: "expense_category",
  PASSENGER: "passenger",
  CHAUFFEUR_ACTIVITY: "activity",
  CHAUFFEUR_SETTING: "organization_setting",
  SEND_NOTIFICATION: "send_notification",
  SEGMENT: "segment_notification",
  EMERGENCY_CONTACT: "emergency_contact",
  HOLIDAY: "holiday",
  OVERTIME_RULE: "overtime_rule",
  ALERT_RULE: "alert_rule",
  BUSSINESS_HOUR: "bussiness_hour",
  DEPARTMENT: "department",
  COMPANY_SETTING: "company_setting",
  APPROVAL_SETTING: "approval_setting",
  APPROVAL: "approval"
}

export const HERE_MAP_KEY = "9mYmeeYB-3xOPR2-L0l1-S1ZNw5NYAZtVUGnKluRTEU"
export const HERE_MAP_APP_ID = "Ayeaqmdzcy9BqxvGdf44"
export const SERVICE_CODE = {
  API_CONTROL_PLANE: "api-control-plane",
  API_CHAUFFEUR: "	api-chauffeur-connect",
  API_ASSET_CONTROL: "api-asset-control",
  EXTERNAL_API_CHAUFFEUR: "chauffeur-connect",
  EXTERNAL_API_ASSET_CONTROL: "asset-control",
  API_OM_API_GATEWAY: "api-om-api-gateway",
  API_IDM: "api-idm",
  API_AMS: "api-ams",
  API_COMMOM: "api-common",
  API_NOTI_INAPP: "api-notification-inapp",
  API_IOT_ACTIVITY: ""
}

export const ENVIRONMENT = [
  {
    label: "Development",
    value: "development"
  },
  {
    label: "Testing",
    value: "testing"
  },
  {
    label: "Staging",
    value: "staging"
  },
  {
    label: "Production",
    value: "production"
  }
]

export const configLocale = {
  designFileId: process.env.REACT_APP_TRANSLATE_DESIGN_FILE,
  projectId: process.env.REACT_APP_TRANSLATE_PROJECT_ID
}

export const PLATFORM = [
  {
    label: "Web",
    value: "web"
  },
  {
    label: "Mobile",
    value: "mobile"
  }
]

export const ACTION_LOGS = [
  {
    label: "Create",
    value: "create"
  },
  {
    label: "Update",
    value: "update"
  },
  {
    label: "Delete",
    value: "delete"
  }
]

export const TYPE_BLOCK_NOTI = {
  APPROVAL: 1,
  NEWS: 2,
  MARKETING: 3,
  PROMOTION: 4,
  EVENT: 5,
  TASK: 6
}

export const Approval_Status = {
  approved: "approved",
  rejected: "rejected",
  in_process: "in_process"
}
export const GENDER_TYPE = {
  MALE: "MALE",
  FEMALE: "FEMALE"
}

export const ORGANIZATION_SETTING_TYPE = {
  adminPassword: 1,
  enduserPassword: 2,
  accessLimit: 3,
  otp: 4
}

export const REPORT_STATUS = {
  PENDING: 0,
  APPROVED: 1,
  DISAPPROVED: 2
}

export const REPORT_TYPES = {
  ABSENCE: 0,
  EXPENSE: 1
}

export const ACTIVITY_TYPE = {
  RIDE: 0,
  MEETING: 1,
  AIRPORT: 2,
  RENTAL: 3,
  PACKAGE: 4
}

export const ACTIVITY_ACTION = {
  ACTIVITY_CSV: "activity_csv",
  ACTIVITY_ONLY_CSV: "activity_only_csv",
  ACTIVITY_WITH_EXPENSE_CSV: "activity_with_expense_csv"
}

export const DRIVER_ACTION = {
  DRIVER_CSV: "driver_csv",
  DRIVER_OVERTIME_CSV: "driver_overtime_csv"
}

export const ACTIVITY_TYPE_MAP = ["Ride", "Meeting", "Airport", "Rental", "Package"]

export const Setting_Password_Policy = {
  allow_special_character: "allow_special_character",
  alphabetic_letter_required: "alphabetic_letter_required",
  lowercase_letter_required: "lowercase_letter_required",
  uppercase_letter_required: "uppercase_letter_required",
  numbers_required: "numbers_required",
  password_min_length: "password_min_length",
  password_max_length: "password_max_length",
  allowed_character_checked: "allowed_character_checked",
  password_enforce_history: "password_enforce_history",
  special_symbol_required: "special_symbol_required"
}

export const ENUMS_CONFIG = {
  method: [
    {
      label: "GET",
      value: "GET"
    },
    {
      label: "POST",
      value: "POST"
    },
    {
      label: "PUT",
      value: "PUT"
    },
    {
      label: "PATCH",
      value: "PATCH"
    },
    {
      label: "DELETE",
      value: "DELETE"
    },
    {
      label: "OPTIONS",
      value: "OPTIONS"
    }
  ],

  service_auth_methods: [
    {
      label: "API Key",
      value: "api_key"
    },
    {
      label: "Basic Auth",
      value: "basic_auth"
    },
    {
      label: "None",
      value: "none"
    }
  ],
  service_protocols: [
    {
      label: "HTTPS",
      value: "https"
    },
    {
      label: "GRPC",
      value: "grpc"
    },
    {
      label: "MQTT",
      value: "mqtt"
    },
    {
      label: "WEBSOCKET",
      value: "websocket"
    }
  ],
  approval_status: [
    {
      label: "In Process",
      value: "in_process"
    },
    {
      label: "Approved",
      value: "approved"
    },
    {
      label: "Rejected",
      value: "rejected"
    }
  ],
  add_to: [
    {
      label: "Header",
      value: "header"
    },
    {
      label: "Query Params",
      value: "query_params"
    }
  ],
  sim_status: [
    {
      label: "Inactive",
      value: "0"
    },
    {
      label: "Active",
      value: "1"
    }
  ],
  sim_connected_status: [
    {
      label: "Disconnect",
      value: "0"
    },
    {
      label: "Connecting",
      value: "1"
    },
    {
      label: "Connected",
      value: "2"
    }
  ],
  device_status: [
    {
      label: "In-stock",
      value: "0",
      color: ""
    },
    {
      label: "Sent",
      value: "1",
      color: "orange"
    },
    {
      label: "Delivered",
      value: "2",
      color: "#108ee9"
    },
    {
      label: "Returning",
      value: "3",
      color: "#f50"
    }
  ],
  receive_device_status: [
    {
      label: "In-transit",
      value: 1,
      color: "orange"
    },
    {
      label: "Received",
      value: 2,
      color: "#108ee9"
    },
    {
      label: "Returned",
      value: 3,
      color: "#f50"
    }
  ],
  vehicle_status: [
    {
      label: "InStock",
      value: 0
    },
    {
      label: "Sent",
      value: 1
    },
    {
      label: "Delivered",
      value: 2
    },
    {
      label: "Returning",
      value: 3
    }
  ],
  organization_type: [
    {
      label: "Tenant",
      value: "1"
    },
    {
      label: "ServiceProvider",
      value: "2"
    },
    {
      label: "Hybrid",
      value: "3"
    }
  ],
  history_action: [
    {
      label: "Create",
      value: "0"
    },
    {
      label: "Update",
      value: "1"
    },
    {
      label: "Update_status",
      value: "2"
    }
  ],
  repeat: [
    {
      label: "I1_10507;866_49171;30_32128",
      value: 1
    },
    {
      label: "I1_10507;866_49169;30_32128",
      value: 0
    }
  ],
  report: [
    { label: "Absence Report", value: 0 },
    { label: "Expense Report", value: 1 }
  ],
  activity_status: [
    { label: "On Going", value: 0 },
    { label: "Upcoming", value: 1 },
    { label: "Completed", value: 2 },
    { label: "Cancelled", value: 3 }
  ]
}

export const SendNotificationType = {
  END_USER: 1,
  PORTAL_USER: 2
}

export const SendNotificationPortalType = {
  CLIENT_PORTAL: 1,
  ADMIN_PORTAL: 2
}

export const SendNotificationStatus = {
  DRAFT: 1,
  PUBLISH: 2
}

export const PHONE_COUNTRY = {
  thaiLand: {
    code: "+66",
    length: 9
  },
  southAfrica: {
    code: "+27",
    length: 9
  },
  japan: {
    code: "+81",
    length: 10
  },
  poland: {
    code: "+590",
    length: 9
  }
}

export const ExpenseCategoryTypes = [
  {
    label: "Express Way",
    value: 1
  },
  {
    label: "Parking",
    value: 2
  },
  {
    label: "Taxi",
    value: 3
  },
  {
    label: "Other",
    value: 0
  }
]

export const EMAIL_TYPE = {
  Confirm: 1,
  Reset: 2,
  ProvisionalRegistration: 3,
  CompleteRegistration: 4
}
