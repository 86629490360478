import Client from "../client/ClientControlIDM"
import ClientMail from "../client/Client"
import version from "../version"
import ClientChauffeur from "../client/ClientChauffeur"
const resource = version + "/internal/user/view"

const login = (data) => {
  return Client("root_admin_user.admin_user_path_create").post(`/api/v1/account/login/`, data)
}
const logout = (data) => {
  return Client("root_admin_user.admin_user_path_create").post(`/api/v1/account/logout/`, data)
}
function getUserInfo(data) {
  return Client("root_admin_user.admin_user_path_view").get(
    `${version}/account/view/profile/`,
    data
  )
}
const getPersonalDataHistory = (params) => {
  return Client("root_admin_user.admin_user_path_view").get(`${version}/personal-data/list/`, {
    params
  })
}
function getOwnSettingAccount(params) {
  return Client("root_admin_user.admin_user_path_view").get(`${version}/org-settings/`, { params })
}
function changePassword(data) {
  return Client("root_admin_user.admin_user_path_update").put(
    `${version}/account/change-password/`,
    data
  )
}
function updateProfile(data) {
  return Client("root_admin_user.admin_user_path_update").put(
    `${version}/account/update/profile/`,
    data
  )
}

function gets(params) {
  return Client("root_admin_user.admin_user_path_view").get(`${resource}/`, { params })
}
function getOrganizations() {
  return Client("root_admin_user.admin_user_path_view").get(`${version}/organization/view/`)
}
function getSelectedOrganizations(params) {
  return ClientChauffeur("root_admin_user.admin_user_path_view").get(
    `${version}/organization/selected/`,
    {
      params
    }
  )
}
function getSelectedUsers(params) {
  return ClientChauffeur("root_admin_user.admin_user_path_view").get(`${version}/user/selected/`, {
    params
  })
}
const getPermissionsRbac = (params) => {
  return Client("root_admin_user.admin_user_path_view").get(`/api/v1/account/rbac/permissions/`, {
    params
  })
}
const getPermissions = (params) => {
  return Client("root_admin_user.admin_user_path_view").get(`/api/v1/account/permissions/`, {
    params
  })
}

function getFunctionList(params) {
  return Client("root_idm_func_setting.idm_func_setting_path_view").get(
    `${version}/internal/function-setting/selected/`,
    { params }
  )
}
function updateFunction(data) {
  return Client("root_idm_func_setting.idm_func_setting_path_update").post(
    `${version}/internal/function-setting/update/`,
    data
  )
}

function getOrganizationSetting() {
  return ClientChauffeur("root_service.service_path_create").get(
    `${version}/client/organization/setting/`
  )
}

function updateOrganizationSetting(data) {
  return ClientChauffeur("root_service.service_path_create").put(
    `${version}/client/organization/setting/`,
    data
  )
}

function sendEmail(data) {
  return ClientMail.post(`${version}/mail/generate-code/`, data)
}

const api = {
  login,
  logout,
  getUserInfo,
  getPersonalDataHistory,
  getOwnSettingAccount,
  changePassword,
  updateProfile,
  gets,
  getOrganizations,
  getSelectedOrganizations,
  getSelectedUsers,
  getOrganizationSetting,
  updateOrganizationSetting,
  getPermissions,
  getFunctionList,
  updateFunction,
  getPermissionsRbac,
  sendEmail
}

export default api
