import queryString from "query-string"
import { getEnums } from "./localstorage"
import { iconWarning } from "./icons"
import axios from "axios"
import { HERE_MAP_KEY } from "lib/Const"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
dayjs.extend(utc)

export function convertPermission(permission) {
  const list = Object.keys(permission)
  let objPermission = {}
  for (let i = 0; i < list.length; i++) {
    let __path = list[i]?.split(".")[0]
    let values = permission[list[i]]
    if (!objPermission[__path]) objPermission[__path] = {}
    for (let i = 0; i < values.length; i++) {
      if (values[i].method === "*") {
        objPermission[__path] = {
          DELETE: true,
          POST: true,
          PUT: true,
          GET: true,
          CLOSE: true,
          DETAIL: true,
          ATRACT: true,
          APPROVAL: true,
          VIEW: true,
          IMPORT: true,
          EXPORT: true
        }
        break
      } else {
        objPermission[__path][values[i].method] = true
      }
    }
  }
  return objPermission
}

export function downloadObjectAsJson(exportObj, exportName) {
  var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(exportObj))
  var downloadAnchorNode = document.createElement("a")
  downloadAnchorNode.setAttribute("href", dataStr)
  downloadAnchorNode.setAttribute("download", exportName + ".json")
  document.body.appendChild(downloadAnchorNode)
  downloadAnchorNode.click()
  downloadAnchorNode.remove()
}

export function convertDurationToClock(n) {
  let h = Math.floor(n / 3600)
  let m = n % 3600
  let _m = Math.floor(m / 60)
  let s = m % 60
  if (h === 0) {
    h = ""
  } else {
    h = h + ":"
  }
  if (_m < 10) _m = "0" + _m
  if (s < 10) s = "0" + s
  return h + _m + ":" + s
}

export function convertTranslates(array) {
  let resource = {}
  for (let i = 0; i < array.length; i++) {
    resource[array[i].file_element.element_id] = array[i].translation_content
  }
  return resource
}
export function convertModal({ text, t }) {
  return {
    className: "modal_confirm",
    icon: null,
    centered: true,
    title: (
      <div>
        <div className="flex justify-center">{iconWarning}</div>
        <div className="text-center">{t("I280_34933;611_93748")}</div>
      </div>
    ),
    cancelText: t("I280_34933;611_95467;30_32137"),
    content: <div className="text-center text-gray-500">{text}</div>,
    okText: t("I280_34933;611_95468;611_95349")
  }
}
export function convertTransalte() {
  let translates = {
    en: "English"
  }
  const translatesStore = localStorage.getItem("language")
  if (translatesStore) translates = JSON.parse(translatesStore)
  let rows = Object.keys(translates).map((i) => {
    return {
      label: translates[i],
      value: i
    }
  })
  return rows
}

export function converDataTree(_list, filter) {
  let list = [..._list]
  function loop(__list) {
    for (let i = 0; i < __list.length; i++) {
      __list[i].value = __list[i].id
      __list[i].title = __list[i].name

      if (__list[i].children && __list[i].children.length > 0) {
        if (filter) {
          __list[i].selectable = true
        } else {
          __list[i].selectable = false
        }
        loop(__list[i].children)
      }
    }
  }
  loop(list)
  return list
}

export const paramsUrl = {
  get: () => {
    return queryString.parse(window.location.search)
  },
  set: (params) => {
    const currentUrlParams = queryString.stringify(params, {
      skipNull: true
    })
    window.history.replaceState(
      {},
      null,
      `${window.location.pathname}?${currentUrlParams.toString()}`
    )
  }
}

export function getSearchParams() {
  return queryString.parse(window.location.search)
}

export function getRole() {
  const role = localStorage.getItem("role")
  return role || false
}

export function fomatCurrency(number) {
  if (number) {
    return parseInt(number)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
  }
  return 0
}

export function getToken() {
  return localStorage.getItem("accessToken")
}

export function convertEnumToDropdown(key) {
  const enums = getEnums()
  return enums[key]
}

export function convertAction(obj, method) {
  const strPermission = localStorage.getItem("permission")
  let permission = []
  if (strPermission) permission = JSON.parse(strPermission)
  for (let i = 0; i < permission.length; i++) {
    if (permission[i].object === obj && permission[i].method === method) return true
    if (
      permission[i].object === obj &&
      method === "EXPORT" &&
      permission[i].resource.includes("export")
    )
      return true
    if (
      permission[i].object === obj &&
      method === "IMPORT" &&
      permission[i].resource.includes("import")
    )
      return true
  }
  return false
  // permission
}

export function getServiceCode(objectCode) {
  const application = JSON.parse(localStorage.getItem("application"))
  return `${application?.organization?.code}-${objectCode}`
}

export async function downloadFile(r) {
  if (!r?._id || !r.file_name.split(".")[1]) return
  const response = await fetch(r?.url, { method: "GET" })
  let blob = await response.blob()
  // blob.name = "xxxx.csv"
  const urlDownload = window.URL.createObjectURL(blob)
  const link = document.createElement("a")
  link.href = urlDownload
  link.setAttribute("download", convertFileName(r))
  link.click()
}

export function convertFileName(r) {
  if (!r?._id) return false
  let names = r.file_name.split("_")
  let fileType = r.file_name.split(".")[1]
  let newName = names[0] + "_" + names[1] + "_" + dayjs(r?.created_at).format("DD_MM_YYYY_HH_mm_ss")

  if (fileType) newName = newName + "." + fileType

  return newName
}

export async function getInitLocation(address) {
  // Build the search address string from the given address object
  const searchAddress = buildSearchAddress(address)

  // Return null early if the address is invalid or empty
  if (!searchAddress) return null

  try {
    // Make an API request to the HERE geocode service with the encoded address
    const response = await axios.get(
      `https://geocode.search.hereapi.com/v1/geocode?q=${encodeURIComponent(searchAddress)}&apiKey=${HERE_MAP_KEY}`
    )
    // Return the data from the API response
    return response.data
  } catch (error) {
    throw error
  }
}

// Helper function to build the search address string by filtering out falsy values
function buildSearchAddress(address) {
  return [
    address?.address1, // Address line 1
    address?.address2, // Address line 2
    address?.city, // City
    address?.province, // Province/State
    address?.country // Country
  ]
    .filter(Boolean) // Remove falsy values (null, undefined, empty strings)
    .join(" ") // Join the remaining values with a space
}

export function convertLocalToUtc(time) {
  return dayjs(time).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
}

export function convertUtcToLocal(time) {
  // Parse UTC time and convert to local time
  return dayjs.utc(time).local().format("YYYY-MM-DD HH:mm")
}

export async function getTimezoneOffset(lat, lng) {
  try {
    const { data } = await axios.get("https://lookup.search.hereapi.com/v1/revgeocode", {
      params: {
        at: `${lat},${lng}`,
        limit: 1,
        apiKey: HERE_MAP_KEY,
        show: "tz"
      }
    })
    return data?.items[0].timeZone.utcOffset
  } catch (error) {}
}

const earthRadius = 6371e3 // Earth's radius in meters

// Function to convert degrees to radians
function toRadians(deg) {
  return (deg * Math.PI) / 180
}

// Function to calculate the Haversine distance between two locations
export function calculateDistance(loc1, loc2) {
  const lat1 = toRadians(loc1.lat)
  const lat2 = toRadians(loc2.lat)
  const deltaLat = toRadians(loc2.lat - loc1.lat)
  const deltaLon = toRadians(loc2.lng - loc1.lng)

  const a =
    Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
    Math.cos(lat1) * Math.cos(lat2) * Math.sin(deltaLon / 2) * Math.sin(deltaLon / 2)

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const distance = earthRadius * c
  return distance // distance in meters
}

export function isInJapan(lat, lng) {
  // Geographic boundaries of Japan
  const latMin = 24 // Minimum latitude (South)
  const latMax = 46 // Maximum latitude (North)
  const lngMin = 122 // Minimum longitude (West)
  const lngMax = 153 // Maximum longitude (East)

  if (typeof lat !== "number" || typeof lng !== "number") {
    return false // If lat or lng is not a number, return false
  }

  // Check if the given lat, lng falls within Japan's boundaries
  return lat >= latMin && lat <= latMax && lng >= lngMin && lng <= lngMax
}
